import {
  DeepReadonly,
  EventsConfig,
  FieldsConfig,
  TriggersConfig
} from "./types";

export const EVENT_DEFAULT: DeepReadonly<CustomEventInit> = {
  bubbles: true,
  cancelable: false,
  detail: {}
};

export const INT_EVENT_NAMESPACE: string = "tracking-int-";
export const GA4_INT_EVENT_NAMESPACE: string = "ga4-tracking-int-";
export const EXT_EVENT_NAMESPACE: string = "analytics-";
export const GA4_EXT_EVENT_NAMESPACE: string = "analytics-";

export const EVENTS: DeepReadonly<EventsConfig> = {
  GA4_VIEW_PAGE: {
    INT_TYPE: `${GA4_INT_EVENT_NAMESPACE}view-page`,
    EXT_TYPE: `${GA4_EXT_EVENT_NAMESPACE}view-page`,
    GTM: "page_view",
    SUBSCRIBERS: ["pushHistory"],
    SPEC: {
      fields: [
        "page",
        "page_location",
        "page_path",
        "page_title",
        "page_type",
        "page_referrer",
        "login_status",
        "page_error",
        "membership_id",
        "user",
        "shopbyroom",
        "cart",
        "cartdetails",
        "paymentlinkvisitedtime",
        "actionField",
        "rh_tokens",
        "galleryname",
        "analytics_storage",
        "searchRule"
      ]
    }
  },
  GA4_VIEW_PAGE_SEARCH: {
    INT_TYPE: `${GA4_INT_EVENT_NAMESPACE}view-page-search-new`,
    EXT_TYPE: `${GA4_EXT_EVENT_NAMESPACE}view-page`,
    GTM: "page_view",
    SUBSCRIBERS: ["pushHistory"],
    SPEC: {
      fields: [
        "page",
        "page_location",
        "page_path",
        "page_title",
        "page_type",
        "login_status",
        "page_error",
        "page_referrer",
        "membership_id",
        "user",
        "campaign",
        "rh_tokens",
        "search_term",
        "searchRule",
        "analytics_storage",
        "search_results_number"
      ]
    }
  },
  SEARCH: {
    INT_TYPE: `${GA4_INT_EVENT_NAMESPACE}search`,
    EXT_TYPE: `${GA4_EXT_EVENT_NAMESPACE}search`,
    GTM: "search",
    SUBSCRIBERS: [],
    SPEC: {
      fields: [
        "page",
        "page_location",
        "page_path",
        "page_title",
        "page_type",
        "page_referrer",
        "searchRule",
        "search_term",
        "search_group",
        "search_term_group",
        "membership_id",
        "user",
        "campaign",
        "rh_tokens",
        "analytics_storage",
        "search_results_number"
      ]
    }
  },
  GA4_VIEW_PAGE_PRODUCT: {
    INT_TYPE: `${GA4_INT_EVENT_NAMESPACE}view-page-product`,
    EXT_TYPE: `${GA4_EXT_EVENT_NAMESPACE}view-page`,
    GTM: "page_view",
    SUBSCRIBERS: ["pushHistory", "settingLocalStorage"],
    SPEC: {
      fields: [
        "page",
        "page_location",
        "page_path",
        "page_title",
        "page_type",
        "login_status",
        "page_error",
        "page_referrer",
        "membership_id",
        "user",
        "campaign",
        "shopbyroom",
        "rh_tokens",
        "analytics_storage",
        "searchRule"
      ]
    }
  },
  SELECT_ITEM: {
    INT_TYPE: `${GA4_INT_EVENT_NAMESPACE}select-item`,
    EXT_TYPE: `${GA4_EXT_EVENT_NAMESPACE}select-item`,
    GTM: "select_item",
    SUBSCRIBERS: [],
    SPEC: {
      fields: [
        "page",
        "page_location",
        "page_path",
        "page_title",
        "page_type",
        "page_error",
        "page_referrer",
        "items",
        "membership_id",
        "user",
        "rh_tokens",
        "analytics_storage",
        "item_list_name"
      ]
    }
  },
  VIEW_ITEM_LIST: {
    INT_TYPE: `${GA4_INT_EVENT_NAMESPACE}view-item-list`,
    EXT_TYPE: `${GA4_EXT_EVENT_NAMESPACE}view-item-list`,
    GTM: "view_item_list",
    SUBSCRIBERS: [],
    SPEC: {
      fields: [
        "page",
        "page_location",
        "page_path",
        "page_title",
        "page_type",
        "page_error",
        "page_referrer",
        "items",
        "membership_id",
        "user",
        "rh_tokens",
        "analytics_storage",
        "item_list_id",
        "item_list_name"
      ]
    }
  },
  VIEW_ITEM: {
    INT_TYPE: `${GA4_INT_EVENT_NAMESPACE}view-item`,
    EXT_TYPE: `${GA4_EXT_EVENT_NAMESPACE}view-item`,
    GTM: "view_item",
    SUBSCRIBERS: [],
    SPEC: {
      fields: [
        "page",
        "page_location",
        "page_path",
        "page_title",
        "page_type",
        "page_error",
        "page_referrer",
        "items",
        "membership_id",
        "user",
        "rh_tokens",
        "analytics_storage",
        "currency",
        "value"
      ]
    }
  },
  // VIEW_PAGE_FAIL: {
  //   INT_TYPE: `${INT_EVENT_NAMESPACE}view-page-fail`,
  //   EXT_TYPE: `${EXT_EVENT_NAMESPACE}view-page-fail`,
  //   GTM: "EEC Fail Page View",
  //   SUBSCRIBERS: [],
  //   SPEC: {
  //     fields: ["operations", "rh_tokens", "analytics_storage"]
  //   }
  // },
  CONTENT_LOADED_HOME: {
    INT_TYPE: `${INT_EVENT_NAMESPACE}content-loaded-home`,
    EXT_TYPE: `${EXT_EVENT_NAMESPACE}content-loaded`,
    GTM: "Content Loaded Home",
    SUBSCRIBERS: [],
    SPEC: {
      fields: ["page", "user", "rh_tokens", "analytics_storage"]
    }
  },
  GA4_ADD_TO_CART: {
    INT_TYPE: `${GA4_INT_EVENT_NAMESPACE}add-to-cart`,
    EXT_TYPE: `${GA4_EXT_EVENT_NAMESPACE}add-to-cart`,
    GTM: "add_to_cart",
    SUBSCRIBERS: [],
    SPEC: {
      fields: [
        "page_type",
        "page_location",
        "page_referrer",
        "page_path",
        "rh_store",
        "store_number",
        "currency",
        "value",
        "items",
        "user",
        "cartdetails",
        "brand",
        "rh_tokens",
        "analytics_storage"
      ]
    }
  },
  ADD_ON_CHECKBOX: {
    INT_TYPE: `${GA4_INT_EVENT_NAMESPACE}add-on-checkbox`,
    EXT_TYPE: `${GA4_EXT_EVENT_NAMESPACE}add-on-checkbox`,
    GTM: "Add-on Checkbox Click",
    SUBSCRIBERS: [],
    SPEC: {
      fields: [
        "user",
        "eventAction",
        "eventlabel",
        "rh_tokens",
        "analytics_storage",
        "page_location",
        "page_referrer"
      ]
    }
  },
  CTA_CLICK: {
    INT_TYPE: `${GA4_INT_EVENT_NAMESPACE}universal-click`,
    EXT_TYPE: `${GA4_EXT_EVENT_NAMESPACE}universal-click`,
    GTM: "cta_click",
    SUBSCRIBERS: [],
    SPEC: {
      fields: [
        "page",
        "page_location",
        "page_referrer",
        "user",
        "click_url",
        "page_path",
        "click_text",
        "click_class",
        "cta_category",
        "tier1",
        "tier2",
        "tier3",
        "tier4",
        "tier5",
        "data-testid",
        "rh_tokens",
        "analytics_storage"
      ]
    }
  },
  VIEW_BANNER: {
    INT_TYPE: `${INT_EVENT_NAMESPACE}view-banner`,
    EXT_TYPE: `${EXT_EVENT_NAMESPACE}view-banner`,
    GTM: "view_banner",
    SUBSCRIBERS: [],
    SPEC: {
      fields: [
        "user",
        "page_location",
        "page_path",
        "page_title",
        "page_type",
        "login_status",
        "page_error",
        "page_referrer",
        "membership_id",
        "tier1",
        "tier2",
        "tier3",
        "tier4",
        "tier5",
        "direction",
        "rh_tokens",
        "analytics_storage"
      ]
    }
  },
  PRE_PDP_CLICK: {
    INT_TYPE: `${INT_EVENT_NAMESPACE}pre-pdp-click`,
    EXT_TYPE: `${EXT_EVENT_NAMESPACE}pre-pdp-click`,
    GTM: "Pre PDP Click",
    SUBSCRIBERS: [],
    SPEC: {
      fields: ["page", "user", "pdpclicktype", "rh_tokens", "analytics_storage"]
    }
  },
  REQ_CONSULTATION: {
    INT_TYPE: `${INT_EVENT_NAMESPACE}request-consultation-submission`,
    EXT_TYPE: `${EXT_EVENT_NAMESPACE}request-consultation`,
    GTM: "Request a Consultation Submission",
    SUBSCRIBERS: [],
    SPEC: {
      fields: [
        "page",
        "category",
        "user",
        "gallery",
        "rh_tokens",
        "analytics_storage"
      ]
    }
  },
  REQ_CONSULTATION_CLICK: {
    INT_TYPE: `${INT_EVENT_NAMESPACE}request-consultation-click`,
    EXT_TYPE: `${EXT_EVENT_NAMESPACE}request-consultation`,
    GTM: "Request a Consultation Click",
    SUBSCRIBERS: [],
    SPEC: {
      fields: ["page", "category", "user", "rh_tokens"]
    }
  },
  ADD_TO_LOCALSTORAGE: {
    INT_TYPE: `${INT_EVENT_NAMESPACE}localstorage`,
    SUBSCRIBERS: ["settingLocalStorage"],
    SPEC: {
      fields: []
    }
  },
  ADD_TO_MEMORY: {
    INT_TYPE: `${INT_EVENT_NAMESPACE}memorystorage`,
    SUBSCRIBERS: ["settingMemoryStorage"],
    SPEC: {
      fields: []
    }
  },
  FUSION_SEARCH_SIGNAL: {
    INT_TYPE: `${INT_EVENT_NAMESPACE}fusion`,
    EXT_TYPE: `${EXT_EVENT_NAMESPACE}fusion-search-signal`,
    GTM: "Fusion Search Signal",
    SUBSCRIBERS: [],
    SPEC: {
      fields: ["page", "search", "user", "rh_tokens", "analytics_storage"]
    }
  },
  SWATCH_PLACE_ORDER: {
    INT_TYPE: `${INT_EVENT_NAMESPACE}swatch-place-order`,
    EXT_TYPE: `${EXT_EVENT_NAMESPACE}swatch-place-order`,
    GTM: "Swatch Place Order",
    SUBSCRIBERS: [],
    SPEC: {
      fields: [
        "page",
        "selectedswatchesobj",
        "user",
        "rh_tokens",
        "analytics_storage"
      ]
    }
  },
  TRADE_REQUEST: {
    INT_TYPE: `${INT_EVENT_NAMESPACE}trade-request-submission`,
    EXT_TYPE: `${EXT_EVENT_NAMESPACE}trade-request`,
    GTM: "Trade Request Submission",
    SUBSCRIBERS: [],
    SPEC: {
      fields: ["page", "user", "tradereq", "rh_tokens", "analytics_storage"]
    }
  },
  TRADE_REQUEST_CLICK: {
    INT_TYPE: `${INT_EVENT_NAMESPACE}trade-request-click`,
    EXT_TYPE: `${EXT_EVENT_NAMESPACE}trade-request`,
    GTM: "Trade Request Click",
    SUBSCRIBERS: [],
    SPEC: {
      fields: ["page", "user", "rh_tokens", "analytics_storage"]
    }
  },
  SUBMIT_ONLINE_RETURN: {
    INT_TYPE: `${INT_EVENT_NAMESPACE}submit-online-return`,
    EXT_TYPE: `${EXT_EVENT_NAMESPACE}submit-online-return`,
    GTM: "Submit Online Return Event",
    SUBSCRIBERS: [],
    SPEC: {
      fields: ["page", "user", "returnitems", "rh_tokens", "analytics_storage"]
    }
  },
  REMOVE_ONLINE_RETURN_ITEM: {
    INT_TYPE: `${INT_EVENT_NAMESPACE}remove-online-return-item`,
    EXT_TYPE: `${EXT_EVENT_NAMESPACE}remove-online-return-item`,
    GTM: "Remove Online Return Item",
    SUBSCRIBERS: [],
    SPEC: {
      fields: ["page", "user", "removedItem", "rh_tokens", "analytics_storage"]
    }
  },
  GA4_PURCHASE: {
    INT_TYPE: `${GA4_INT_EVENT_NAMESPACE}order-placed`,
    EXT_TYPE: `${GA4_EXT_EVENT_NAMESPACE}order-placed`,
    GTM: "purchase",
    SUBSCRIBERS: [],
    SPEC: {
      fields: [
        "page",
        "transaction",
        "rh_store",
        "order_date",
        "store_number",
        "payment_type",
        "coupon",
        "currency",
        "value",
        "tax",
        "shipping",
        "items",
        "transaction_id",
        "user",
        "rh_tokens",
        "analytics_storage",
        "user",
        "galleryname",
        "paymentlinkvisitedtime",
        "page_location",
        "page_referrer"
      ]
    }
  },
  BEGIN_CHECKOUT: {
    INT_TYPE: `${GA4_INT_EVENT_NAMESPACE}eec-checkout`,
    EXT_TYPE: `${GA4_EXT_EVENT_NAMESPACE}eec-checkout`,
    GTM: "begin_checkout",
    SUBSCRIBERS: [],
    SPEC: {
      fields: [
        "page",
        "page_type",
        "page_location",
        "page_referrer",
        "page_path",
        "currency",
        "value",
        "items",
        "user",
        "checkout_step",
        "rh_store",
        "store_number",
        "brand",
        "selected_option_from_dropdown",
        "rh_tokens",
        "analytics_storage",
        "user"
      ]
    }
  },
  VIEW_CART: {
    INT_TYPE: `${GA4_INT_EVENT_NAMESPACE}view_cart`,
    EXT_TYPE: `${GA4_EXT_EVENT_NAMESPACE}view_cart`,
    GTM: "view_cart",
    SUBSCRIBERS: [],
    SPEC: {
      fields: [
        "page_type",
        "page_location",
        "page_referrer",
        "page_path",
        "rh_store",
        "value",
        "currency",
        "items",
        "user",
        "store_number",
        "rh_tokens",
        "analytics_storage"
      ]
    }
  },
  REMOVE_FROM_CART: {
    INT_TYPE: `${GA4_INT_EVENT_NAMESPACE}remove_from_cart`,
    EXT_TYPE: `${GA4_EXT_EVENT_NAMESPACE}remove_from_cart`,
    GTM: "remove_from_cart",
    SUBSCRIBERS: [],
    SPEC: {
      fields: [
        "page_type",
        "page_location",
        "page_referrer",
        "page_path",
        "rh_store",
        "value",
        "currency",
        "items",
        "user",
        "store_number",
        "rh_tokens",
        "analytics_storage"
      ]
    }
  },
  VIDEO_PLAY: {
    INT_TYPE: `${INT_EVENT_NAMESPACE}video-play`,
    EXT_TYPE: `${EXT_EVENT_NAMESPACE}video-play`,
    GTM: "VIDEO PLAY",
    SUBSCRIBERS: [],
    SPEC: {
      fields: ["video", "rh_tokens", "analytics_storage"]
    }
  },
  VIDEO_PAUSE: {
    INT_TYPE: `${INT_EVENT_NAMESPACE}video-pause`,
    EXT_TYPE: `${EXT_EVENT_NAMESPACE}video-pause`,
    GTM: "VIDEO PAUSE",
    SUBSCRIBERS: [],
    SPEC: {
      fields: ["video", "rh_tokens", "analytics_storage"]
    }
  },
  VIDEO_PROGRESS: {
    INT_TYPE: `${INT_EVENT_NAMESPACE}video-progress`,
    EXT_TYPE: `${EXT_EVENT_NAMESPACE}video-progress`,
    GTM: "VIDEO PROGRESS",
    SUBSCRIBERS: [],
    SPEC: {
      fields: ["video", "rh_tokens", "analytics_storage"]
    }
  },
  VIDEO_JUMP: {
    INT_TYPE: `${INT_EVENT_NAMESPACE}video-jump`,
    EXT_TYPE: `${EXT_EVENT_NAMESPACE}video-jump`,
    GTM: "VIDEO JUMP",
    SUBSCRIBERS: [],
    SPEC: {
      fields: ["video", "rh_tokens", "analytics_storage"]
    }
  },
  EMAIL_SUBSCRIPTION: {
    INT_TYPE: `${INT_EVENT_NAMESPACE}email-subscribed`,
    EXT_TYPE: `${EXT_EVENT_NAMESPACE}email-subscribed`,
    GTM: "EEC EMAIL SUBSCRIBED",
    SUBSCRIBERS: [],
    SPEC: {
      fields: [
        "page",
        "user",
        "subscribedbrands",
        "rh_tokens",
        "analytics_storage"
      ]
    }
  },
  CG_CLICKS: {
    INT_TYPE: `${INT_EVENT_NAMESPACE}cg-links`,
    EXT_TYPE: `${EXT_EVENT_NAMESPACE}cg-links`,
    GTM: "CG CLICKS",
    SUBSCRIBERS: [],
    SPEC: {
      fields: ["page", "user", "cgclicktype", "rh_tokens", "analytics_storage"]
    }
  },
  PG_CLICKS: {
    INT_TYPE: `${INT_EVENT_NAMESPACE}pg-links`,
    EXT_TYPE: `${EXT_EVENT_NAMESPACE}pg-links`,
    GTM: "PG CLICKS",
    SUBSCRIBERS: [],
    SPEC: {
      fields: ["page", "user", "pgclicktype", "rh_tokens", "analytics_storage"]
    }
  },
  GA4_FORM_SUBMIT: {
    INT_TYPE: `${GA4_INT_EVENT_NAMESPACE}form-submit`,
    EXT_TYPE: `${GA4_EXT_EVENT_NAMESPACE}form-submit`,
    GTM: "form_submit",
    SUBSCRIBERS: [],
    SPEC: {
      fields: [
        "page_location",
        "page_path",
        "page_title",
        "page_type",
        "page_referrer",
        "login_status",
        "page_error",
        "membership_id",
        "form_name",
        "form_start",
        "form_type",
        "form_submit_status",
        "user"
      ]
    }
  },
  GA4_WISHLIST: {
    INT_TYPE: `${GA4_INT_EVENT_NAMESPACE}add-to-wishlist`,
    EXT_TYPE: `${GA4_EXT_EVENT_NAMESPACE}add-to-wishlist`,
    GTM: "add_to_wishlist",
    SUBSCRIBERS: [],
    SPEC: {
      fields: [
        "page_type",
        "page_location",
        "page_referrer",
        "page_path",
        "rh_store",
        "store_number",
        "currency",
        "value",
        "items",
        "brand",
        "rh_tokens",
        "analytics_storage",
        "user"
      ]
    }
  },
  GA4_ADD_SHIPPING_INFO: {
    INT_TYPE: `${GA4_INT_EVENT_NAMESPACE}add-shipping-info`,
    EXT_TYPE: `${GA4_EXT_EVENT_NAMESPACE}add-shipping-info`,
    GTM: "add_shipping_info",
    SUBSCRIBERS: [],
    SPEC: {
      fields: [
        "page_type",
        "page_location",
        "page_referrer",
        "page_path",
        "rh_store",
        "store_number",
        "currency",
        "items",
        "brand",
        "rh_tokens",
        "analytics_storage",
        "coupon",
        "value",
        "user"
      ]
    }
  },
  GA4_ADD_PAYMENT_INFO: {
    INT_TYPE: `${GA4_INT_EVENT_NAMESPACE}add-payment-info`,
    EXT_TYPE: `${GA4_EXT_EVENT_NAMESPACE}add-payment-info`,
    GTM: "add_payment_info",
    SUBSCRIBERS: [],
    SPEC: {
      fields: [
        "page_type",
        "page_location",
        "page_referrer",
        "page_path",
        "currency",
        "value",
        "payment_type",
        "items",
        "user",
        "rh_tokens",
        "analytics_storage"
      ]
    }
  },
  LOGIN: {
    INT_TYPE: `${GA4_INT_EVENT_NAMESPACE}login`,
    EXT_TYPE: `${GA4_EXT_EVENT_NAMESPACE}login`,
    GTM: "login",
    SUBSCRIBERS: [],
    SPEC: {
      fields: [
        "user",
        "user_id",
        "page_location",
        "page_path",
        "page_title",
        "page_type",
        "page_error",
        "login_status",
        "login_method",
        "page_referrer",
        "rh_tokens",
        "analytics_storage"
      ]
    }
  },
  REGISTER: {
    INT_TYPE: `${GA4_INT_EVENT_NAMESPACE}signup`,
    EXT_TYPE: `${GA4_EXT_EVENT_NAMESPACE}signup`,
    GTM: "signup",
    SUBSCRIBERS: [],
    SPEC: {
      fields: [
        "user",
        "user_id",
        "page_location",
        "page_path",
        "page_title",
        "page_type",
        "page_error",
        "signup_status",
        "signup_method",
        "page_referrer",
        "rh_tokens",
        "analytics_storage"
      ]
    }
  },
  VIDEO_START: {
    INT_TYPE: `${GA4_INT_EVENT_NAMESPACE}video-start`,
    EXT_TYPE: `${GA4_EXT_EVENT_NAMESPACE}video-start`,
    GTM: "video_start",
    SUBSCRIBERS: [],
    SPEC: {
      fields: [
        "user",
        "user_id",
        "page_location",
        "page_path",
        "page_title",
        "page_type",
        "page_error",
        "page_referrer",
        "video_title",
        "rh_tokens",
        "analytics_storage"
      ]
    }
  },
  VIDEO_IN_PROGRESS: {
    INT_TYPE: `${GA4_INT_EVENT_NAMESPACE}video-progress`,
    EXT_TYPE: `${GA4_EXT_EVENT_NAMESPACE}video-progress`,
    GTM: "video_progress",
    SUBSCRIBERS: [],
    SPEC: {
      fields: [
        "user",
        "user_id",
        "page_location",
        "page_path",
        "page_title",
        "page_type",
        "page_error",
        "page_referrer",
        "video_title",
        "video_progress",
        "rh_tokens",
        "analytics_storage"
      ]
    }
  },
  VIDEO_COMPLETE: {
    INT_TYPE: `${GA4_INT_EVENT_NAMESPACE}video-complete`,
    EXT_TYPE: `${GA4_EXT_EVENT_NAMESPACE}video-complete`,
    GTM: "video_complete",
    SUBSCRIBERS: [],
    SPEC: {
      fields: [
        "user",
        "user_id",
        "page_location",
        "page_path",
        "page_title",
        "page_type",
        "page_error",
        "page_referrer",
        "video_title",
        "rh_tokens",
        "analytics_storage"
      ]
    }
  },
  COUNTRY_SWITCHER_POPUP: {
    INT_TYPE: `${GA4_INT_EVENT_NAMESPACE}country_switcher_popup`,
    EXT_TYPE: `${GA4_EXT_EVENT_NAMESPACE}country_switcher_popup`,
    GTM: "country_switcher_popup",
    SUBSCRIBERS: [],
    SPEC: {
      fields: [
        "page",
        "page_location",
        "page_path",
        "page_title",
        "page_type",
        "page_error",
        "page_referrer",
        "analytics_storage",
        "rh_tokens",
        "user"
      ]
    }
  },
  FACET_CLICK: {
    INT_TYPE: `${GA4_INT_EVENT_NAMESPACE}facet-click`,
    EXT_TYPE: `${GA4_EXT_EVENT_NAMESPACE}facet-click`,
    GTM: "facet-click",
    SUBSCRIBERS: [],
    SPEC: {
      fields: [
        "page_type",
        "page_location",
        "page_referrer",
        "page_path",
        "facet_name",
        "facet_value",
        "search_term",
        "user",
        "searchRule",
        "rh_tokens",
        "analytics_storage"
      ]
    }
  },
  ADD_COUPON: {
    INT_TYPE: `${GA4_INT_EVENT_NAMESPACE}add-coupon`,
    EXT_TYPE: `${GA4_EXT_EVENT_NAMESPACE}add-coupon`,
    GTM: "add_coupon",
    SUBSCRIBERS: [],
    SPEC: {
      fields: [
        "page_type",
        "page_location",
        "page_referrer",
        "page_path",
        "coupon",
        "method",
        "user",
        "rh_tokens",
        "analytics_storage"
      ]
    }
  },
  NEW_TAB_OPENED: {
    INT_TYPE: `${GA4_INT_EVENT_NAMESPACE}new-tab-opened`,
    EXT_TYPE: `${GA4_EXT_EVENT_NAMESPACE}new-tab-opened`,
    GTM: "new_tab_opened",
    SUBSCRIBERS: ["pushHistory"],
    SPEC: {
      fields: [
        "page",
        "page_location",
        "page_path",
        "page_title",
        "page_type",
        "page_referrer",
        "login_status",
        "page_error",
        "membership_id",
        "concurrent_open_tabs",
        "user",
        "rh_tokens",
        "analytics_storage"
      ]
    }
  }
};

export const FIELDS: DeepReadonly<FieldsConfig> = {
  page: {
    dependencies: ["location", "history", "prevHistory"]
  },
  search: {
    dependencies: ["location"]
  },
  searches: {
    // TODO
    dependencies: []
  },
  paymentlinkvisitedtime: {
    dependencies: []
  },
  user: {
    dependencies: []
  },
  campaign: {
    // TODO
    dependencies: []
  },
  product: {
    dependencies: ["location"]
  },
  channel: {
    // TODO
    dependencies: []
  },
  channelentry: {
    // TODO
    dependencies: []
  },
  // operations: {
  //   dependencies: []
  // },
  items: {
    dependencies: ["location", "local"]
  },
  item_list_id: {
    dependencies: ["location", "local"]
  },
  item_list_name: {
    dependencies: ["location", "local"]
  },
  items_shipping: {
    dependencies: ["location", "local"]
  },
  tier1: {
    dependencies: ["history"]
  },
  tier2: {
    dependencies: ["history"]
  },
  tier3: {
    dependencies: ["history"]
  },
  tier4: {
    dependencies: ["history"]
  },
  tier5: {
    dependencies: ["history"]
  },
  form_name: {
    dependencies: ["local"]
  },
  form_id: {
    dependencies: ["local"]
  },
  form_start: {
    dependencies: ["local"]
  },
  form_type: {
    dependencies: ["local"]
  },
  form_submit_status: {
    dependencies: ["local"]
  },
  pli: {
    dependencies: ["local"]
  },
  category: {
    dependencies: ["location"]
  },
  gallery: {
    dependencies: ["local"]
  },
  selectedswatchesobj: {
    dependencies: []
  },
  shopbyroom: {
    dependencies: []
  },
  pdpclicktype: {
    dependencies: ["history"]
  },
  tradereq: {
    dependencies: ["local"]
  },
  chattopic: {
    dependencies: ["local"]
  },
  zeroresult: {
    dependencies: ["local"]
  },
  returnitems: {
    dependencies: ["local"]
  },
  removedItem: {
    dependencies: ["local"]
  },
  chatendreason: {
    dependencies: ["local"]
  },
  cart: {
    dependencies: ["local"]
  },
  cartdetails: {
    dependencies: ["local"]
  },
  transaction: {
    dependencies: ["local"]
  },
  video: {
    dependencies: ["local"]
  },
  actionField: {
    dependencies: ["local"]
  },
  subscribedbrands: {
    dependencies: []
  },
  collectioncategory: {
    dependencies: ["local"]
  },
  layout: {
    dependencies: ["local"]
  },
  searchRule: {
    dependencies: ["local"]
  },
  rh_tokens: {
    dependencies: []
  },
  galleryname: {
    dependencies: []
  },
  analytics_storage: {
    dependencies: []
  },
  page_location: {
    dependencies: ["location"]
  },
  page_path: {
    dependencies: ["history"]
  },
  page_title: {
    dependencies: []
  },
  page_type: {
    dependencies: []
  },
  login_status: {
    dependencies: ["history"]
  },
  page_error: {
    dependencies: []
  },
  membership_id: {
    dependencies: []
  },
  page_referrer: {
    dependencies: []
  },
  click_url: {
    dependencies: ["history"]
  },
  click_text: {
    dependencies: ["history"]
  },
  click_class: {
    dependencies: ["history"]
  },
  cta_category: {
    dependencies: ["history"]
  },
  "data-testid": {
    dependencies: ["history"]
  },
  is_member: {
    dependencies: ["history"]
  },
  rh_store: {
    dependencies: ["history"]
  },
  order_date: {
    dependencies: ["history"]
  },
  store_number: {
    dependencies: ["history"]
  },
  payment_type: {
    dependencies: ["history"]
  },
  coupon: {
    dependencies: ["history"]
  },
  currency: {
    dependencies: []
  },
  value: {
    dependencies: ["history", "local"]
  },
  tax: {
    dependencies: ["history", "local"]
  },
  shipping: {
    dependencies: ["history", "local"]
  },
  transaction_id: {
    dependencies: ["history"]
  },
  customer: {
    dependencies: ["history"]
  },
  eventlabel: {
    dependencies: ["history"]
  },
  eventAction: {
    dependencies: []
  },
  brand: {
    dependencies: []
  },
  checkout_step: {
    dependencies: []
  },
  direction: {
    dependencies: ["history"]
  },
  search_term: {
    dependencies: ["location"]
  },
  search_results_number: {
    dependencies: ["local"]
  },
  search_group: {
    dependencies: []
  },
  search_term_group: {
    dependencies: ["local"]
  },
  cgclicktype: {
    dependencies: ["history"]
  },
  pgclicktype: {
    dependencies: ["history"]
  },
  user_id: {
    dependencies: ["history"]
  },
  login_method: {
    dependencies: ["local"]
  },
  signup_method: {
    dependencies: ["local"]
  },
  signup_status: {
    dependencies: ["local"]
  },
  video_progress: {
    dependencies: ["local"]
  },
  video_title: {
    dependencies: ["local"]
  },
  facet_name: {
    dependencies: ["local"]
  },
  facet_value: {
    dependencies: ["local"]
  },
  selected_option_from_dropdown: {
    dependencies: ["local"]
  },
  method: {
    dependencies: ["history"]
  },
  concurrent_open_tabs: {
    dependencies: ["local"]
  }
};

export const DEPENDENCY_ORDER: DeepReadonly<Array<string>> = [
  "location",
  "history",
  "local",
  "prevHistory"
];

const CLICK_DELEGATES = [
  `.aem-container [data-analytics-id='rh-carousels'] button *`,
  `.aem-container [data-analytics-id='previousIcon']`,
  `.aem-container [data-analytics-id='nextIcon']`,
  `.aem-container [data-analytics-id='collection-carousel'] *`,
  `.aem-container [data-analytics-id='collection-text'] *`
];

const DELEGATES = [
  `#footer-menus li a`,
  `#footer-menus li a *`,
  `.MuiToolbar-root [data-analytics-id='link'] *`,
  `.MuiToolbar-root [data-analytics-nav='account-icon'] *`,
  `.aem-container [data-analytics-id='box'] a`,
  `.aem-container [data-analytics-id='box'] a span`,
  `.aem-container [data-analytics-id='box'] button`,
  `.aem-container [data-analytics-id='box'] button *`,
  `.aem-container [data-analytics-id='box'] img`,
  `.aem-container [data-analytics-id='gallery'] p img`,
  `.aem-container [data-analytics-id='link']`,
  `.aem-container [data-analytics-id='link'] *`,
  `.aem-container [data-analytics-id='rh-image'] *`,
  `.aem-container [data-analytics-id='worh_quads'] a div`,
  `nav [data-analytics-id='topNav'] a`,
  `nav [data-analytics-id='topNav'] a img`,
  `nav [data-analytics-id='topNav'] a span`,
  `nav [data-analytics-id='topNav'] button *`,
  `nav section a`,
  `ul li[id^="/my-account"]`,
  `ul li[id^="/my-account"] *`,
  `.MuiToolbar-root a *:not(#rhr-sale-navigation-dropdown *)`,
  `.MuiToolbar-root button[id="search-icon"] *`,
  `[data-testid="/sign-in"] *`,
  `#brand-links-container ul li span`,
  `.MuiGrid-root MuiGrid-item a h4`,
  `.MuiToolbar-root #container-rhrSearchField_search-btn *`,
  `.MuiToolbar-root input`,
  `ul li a[data-worh-type='worh-icon'] span`,
  `a[data-worh-type='worh-icon'] p`,
  `a[data-worh-type='worh-icon'] h3`,
  `a[data-worh-type='worh-icon'] h4`,
  `a[data-worh-type='worh-icon'] h6`,
  `[data-worh-type='worh-icon'] #component-rh-image_wrapper img`,
  `footer ul span li a span`,
  `a[data-worh-type='worh-footer'] p`,
  `ul li[data-worh-type='worh-nav-footer'] h4`,
  `ul li[data-worh-type='worh-icon'] *`,
  `ul li[data-worh-type='rhr-uber-cat'] h4`,
  `ul li[data-worh-type='rhr-sub-cat'] h4`
];

export const TRIGGERS: DeepReadonly<TriggersConfig> = {
  BY_GLOBAL_EVENT: [
    // {
    //   EVENT: "error",
    //   HOSTS: ["window"],
    //   EMIT: EVENTS.VIEW_PAGE_FAIL.INT_TYPE
    // },
    // {
    //   EVENT: "error",
    //   HOSTS: ["window"],
    //   EMIT: EVENTS.GA4_VIEW_PAGE.INT_TYPE
    // },
    {
      EVENT: "cta_click",
      HOSTS: ["body"],
      EMIT: EVENTS.CTA_CLICK.INT_TYPE
    },
    {
      EVENT: "pre-pdp-click",
      HOSTS: ["body"],
      EMIT: EVENTS.PRE_PDP_CLICK.INT_TYPE
    },
    {
      EVENT: "view_banner",
      HOSTS: ["body"],
      EMIT: EVENTS.VIEW_BANNER.INT_TYPE
    },
    {
      EVENT: "cg-click",
      HOSTS: ["body"],
      EMIT: EVENTS.CG_CLICKS.INT_TYPE
    },
    {
      EVENT: "pg-click",
      HOSTS: ["body"],
      EMIT: EVENTS.PG_CLICKS.INT_TYPE
    }
  ],
  BY_DOM_EVENT: [
    {
      EVENT: "click",
      HOSTS: ["body"],
      EMIT: EVENTS.CTA_CLICK.INT_TYPE,
      DELEGATES: [...CLICK_DELEGATES, ...DELEGATES]
    },
    {
      EVENT: "click",
      HOSTS: ["body"],
      EMIT: EVENTS.ADD_TO_MEMORY.INT_TYPE,
      DELEGATES: [`[data-analytics-topcat-id]`, `[data-analytics-topcat-id] *`]
    },
    {
      EVENT: "touchstart",
      HOSTS: ["body"],
      EMIT: EVENTS.VIEW_BANNER.INT_TYPE,
      DELEGATES: [
        `.aem-container [data-analytics-id='rh-carousels']`,
        `.aem-container [data-analytics-id='rh-carousels'] *`,
        `.MuiGrid-root [data-analytics-id='rh-carousels']`,
        `.MuiGrid-root [data-analytics-id='rh-carousels'] *`
      ]
    },
    {
      EVENT: "touchend",
      HOSTS: ["body"],
      EMIT: EVENTS.VIEW_BANNER.INT_TYPE,
      DELEGATES: [
        `.aem-container [data-analytics-id='rh-carousels']`,
        `.aem-container [data-analytics-id='rh-carousels'] *`,
        `.MuiGrid-root [data-analytics-id='rh-carousels']`,
        `.MuiGrid-root [data-analytics-id='rh-carousels'] *`
      ]
    }
  ]
};

export const BrandsSubcription = {
  RH: false,
  BC: false,
  TN: false,
  MO: false,
  OUTLET: false
};
